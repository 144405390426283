export default {
    'local-commercial': {
        country: 'Location local commercial en France',
        prefix: 'Location local commercial'
    },
    'local-commercial-vente': {
        country: 'Vente local commercial en France',
        prefix: 'Vente local commercial'
    },
    'fonds-commerce': {
        country: 'Vente fonds de commerce en France',
        prefix: 'Vente fonds de commerce'
    },
    'bureau': {
        country: 'Location bureaux en France',
        prefix: 'Location bureaux'
    },
    'bureau-vente': {
        country: 'Vente bureaux en France',
        prefix: 'Vente bureaux'
    },
    'entrepot': {
        country: 'Location entrepôts / locaux d\'activités en France',
        prefix: 'Location entrepôts / locaux d\'activités'
    },
    'entrepot-vente': {
        country: 'Vente entrepôts / locaux d\'activités en France',
        prefix: 'Vente entrepôts / locaux d\'activités'
    },
    'coworking': {
        country: 'Espaces de coworking en France',
        prefix: 'Espaces de coworking'
    },
    'popup-store': {
        country: 'Location pop-up stores en France',
        prefix: 'Location pop-up stores'
    },
    'terrain': {
        country: 'Vente terrain en France',
        prefix: 'Vente terrain'
    }
}