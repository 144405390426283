export default {
    '53': 'Bretagne',
    '24': 'Centre-Val de Loire',
    '94': 'Corse',
    '11': 'Île-de-France',
    '52': 'Pays de la Loire',
    '93': 'Provence-Alpes-Côte d\'Azur',
    '27': 'Bourgogne-Franche-Comté',
    '75': 'Nouvelle-Aquitaine',
    '28': 'Normandie',
    '44': 'Grand Est',
    '76': 'Occitanie',
    '32': 'Hauts-de-France',
    '84': 'Auvergne-Rhône-Alpes'
}
