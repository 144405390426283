export default {
    Bretagne: '53',
    'Centre-Val de Loire': '24',
    Corse: '94',
    'Île-de-France': '11',
    'Pays de la Loire': '52',
    "Provence-Alpes-Côte d'Azur": '93',
    'Bourgogne-Franche-Comté': '27',
    'Nouvelle-Aquitaine': '75',
    Normandie: '28',
    'Grand Est': '44',
    Occitanie: '76',
    'Hauts-de-France': '32',
    'Auvergne-Rhône-Alpes': '84'
}
