export default {
    Bretagne: "bretagne",
    "Centre-Val de Loire": "centre-val-de-loire",
    Corse: "corse",
    "Île-de-France": "ile-de-france",
    "Pays de la Loire": "pays-de-la-loire",
    "Provence-Alpes-Côte d'Azur": "provence-alpes-cote-d-azur",
    "Bourgogne-Franche-Comté": "bourgogne-franche-comte",
    "Nouvelle-Aquitaine": "nouvelle-aquitaine",
    Normandie: "normandie",
    "Grand Est": "grand-est",
    Occitanie: "occitanie",
    "Hauts-de-France": "hauts-de-france",
    "Auvergne-Rhône-Alpes": "auvergne-rhone-alpes"
}
