const specialCaseCpGPS = {
    "75001": { lon: 2.334396, lat: 48.864614 },
    "75002": { lon: 2.341688, lat: 48.868743 },
    "75003": { lon: 2.360936, lat: 48.864212 },
    "75004": { lon: 2.355619, lat: 48.856202 },
    "75005": { lon: 2.34435, lat: 48.845973 },
    "75006": { lon: 2.332951, lat: 48.850433 },
    "75007": { lon: 2.320195, lat: 48.857028 },
    "75008": { lon: 2.31765, lat: 48.87748 },
    "75009": { lon: 2.339962, lat: 48.876019 },
    "75010": { lon: 2.359839, lat: 48.876126 },
    "75011": { lon: 2.379703, lat: 48.858416 },
    "75012": { lon: 2.395752, lat: 48.839615 },
    "75013": { lon: 2.355583, lat: 48.832397 },
    "75014": { lon: 2.326959, lat: 48.833059 },
    "75015": { lon: 2.300383, lat: 48.84137 },
    "75016": { lon: 2.275765, lat: 48.863171 },
    "75116": { lon: 2.275765, lat: 48.863171 },
    "75017": { lon: 2.322364, lat: 48.884224 },
    "75018": { lon: 2.346467, lat: 48.890012 },
    "75019": { lon: 2.38436, lat: 48.889343 },
    "75020": { lon: 2.398929, lat: 48.865042 },
    "13001": { lon: 5.382502, lat: 43.300067 },
    "13002": { lon: 5.359067, lat: 43.316216 },
    "13003": { lon: 5.378941, lat: 43.311385 },
    "13004": { lon: 5.40113, lat: 43.306983 },
    "13005": { lon: 5.397644, lat: 43.292212 },
    "13006": { lon: 5.380516, lat: 43.2868 },
    "13007": { lon: 5.362413, lat: 43.282997 },
    "13008": { lon: 5.378865, lat: 43.243032 },
    "13009": { lon: 5.449898, lat: 43.234073 },
    "13010": { lon: 5.425821, lat: 43.276838 },
    "13011": { lon: 5.49062, lat: 43.293256 },
    "13012": { lon: 5.439345, lat: 43.308457 },
    "13013": { lon: 5.432082, lat: 43.346731 },
    "13014": { lon: 5.390592, lat: 43.346361 },
    "13015": { lon: 5.364765, lat: 43.353156 },
    "13016": { lon: 5.316452, lat: 43.362571 },
    "69001": { lon: 4.83549, lat: 45.767699 },
    "69002": { lon: 4.827374, lat: 45.751112 },
    "69003": { lon: 4.849389, lat: 45.759934 },
    "69004": { lon: 4.825031, lat: 45.778046 },
    "69005": { lon: 4.828436, lat: 45.763571 },
    "69006": { lon: 4.849432, lat: 45.768309 },
    "69007": { lon: 4.84189, lat: 45.745755 },
    "69008": { lon: 4.87072, lat: 45.735123 },
    "69009": { lon: 4.806249, lat: 45.774 }
}

export default specialCaseCpGPS