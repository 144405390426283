import firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"
import "firebase/firestore"
import "firebase/storage"
import getConfig from "next/config"

let initPromise = config => {
    try {
        const clientCredentials =
            typeof getConfig() !== "undefined"
                ? getConfig().publicRuntimeConfig.clientCredentials
                : config.clientCredentials

        // console.log("clientCredentials: ", clientCredentials)

        if (!firebase.apps.length) {
            firebase.initializeApp(clientCredentials)
        }

        return firebase
    } catch (e) {
        console.log("initPromise error: ", e)
    }
}

let instance = null

class FirebaseInstance {
    constructor () {
        if (!instance) {
            instance = this
        }

        this.fi = null

        return instance
    }

    init (config = null) {
        if (this.fi !== null) return this.fi
        this.fi = initPromise(config)
        return this.fi
    }
}

export default FirebaseInstance
