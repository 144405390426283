export default [
    {
        code: "01",
        codeRegion: "84",
        name: "Ain",
        url: "ain",
        seo: "ain-01",
        coordinates: [5.348888889, 46.09944444]
    },
    {
        code: "02",
        codeRegion: "32",
        name: "Aisne",
        url: "aisne",
        seo: "aisne-02",
        coordinates: [3.558333333, 49.55944444]
    },
    {
        code: "03",
        codeRegion: "84",
        name: "Allier",
        url: "allier",
        seo: "allier-03",
        coordinates: [3.188333333, 46.39361111]
    },
    {
        code: "04",
        codeRegion: "93",
        name: "Alpes-de-Haute-Provence",
        url: "alpes-de-haute-provence",
        seo: "alpes-de-haute-provence-04",
        coordinates: [6.243888889, 44.10611111]
    },
    {
        code: "05",
        codeRegion: "93",
        name: "Hautes-Alpes",
        url: "hautes-alpes",
        seo: "hautes-alpes-05",
        coordinates: [6.263055556, 44.66361111]
    },
    {
        code: "06",
        codeRegion: "93",
        name: "Alpes-Maritimes",
        url: "alpes-maritimes",
        seo: "alpes-maritimes-06",
        coordinates: [7.116388889, 43.9375]
    },
    {
        code: "07",
        codeRegion: "84",
        name: "Ardèche",
        url: "ardeche",
        seo: "ardeche-07",
        coordinates: [4.424722222, 44.75166667]
    },
    {
        code: "08",
        codeRegion: "44",
        name: "Ardennes",
        url: "ardennes",
        seo: "ardennes-08",
        coordinates: [4.640833333, 49.61555556]
    },
    {
        code: "09",
        codeRegion: "76",
        name: "Ariège",
        url: "ariege",
        seo: "ariege-09",
        coordinates: [1.503888889, 42.92083333]
    },
    {
        code: "10",
        codeRegion: "44",
        name: "Aube",
        url: "aube",
        seo: "aube-10",
        coordinates: [4.161666667, 48.30444444]
    },
    {
        code: "11",
        codeRegion: "76",
        name: "Aude",
        url: "aude",
        seo: "aude-11",
        coordinates: [2.414166667, 43.10333333]
    },
    {
        code: "12",
        codeRegion: "76",
        name: "Aveyron",
        url: "aveyron",
        seo: "aveyron-12",
        coordinates: [2.679722222, 44.28027778]
    },
    {
        code: "13",
        codeRegion: "93",
        name: "Bouches-du-Rhône",
        url: "bouches-du-rhone",
        seo: "bouches-du-rhone-13",
        coordinates: [5.086388889, 43.54333333]
    },
    {
        code: "14",
        codeRegion: "28",
        name: "Calvados",
        url: "calvados",
        seo: "calvados-14",
        coordinates: [-0.363611111, 49.09972222]
    },
    {
        code: "15",
        codeRegion: "84",
        name: "Cantal",
        url: "cantal",
        seo: "cantal-15",
        coordinates: [2.668611111, 45.05111111]
    },
    {
        code: "16",
        codeRegion: "75",
        name: "Charente",
        url: "charente",
        seo: "charente-16",
        coordinates: [0.201666667, 45.71805556]
    },
    {
        code: "17",
        codeRegion: "75",
        name: "Charente-Maritime",
        url: "charente-maritime",
        seo: "charente-maritime-17",
        coordinates: [-0.674444444, 45.78083333]
    },
    {
        code: "18",
        codeRegion: "24",
        name: "Cher",
        url: "cher",
        seo: "cher-18",
        coordinates: [2.491111111, 47.06472222]
    },
    {
        code: "19",
        codeRegion: "75",
        name: "Corrèze",
        url: "correze",
        seo: "correze-19",
        coordinates: [1.876944444, 45.35694444]
    }, 
    {
        code: "2A",
        codeRegion: "94",
        name: "Corse-du-sud",
        url: "corse-du-sud",
        seo: "corse-du-sud-2a",
        coordinates: [8.988055556, 41.86361111]
    },
    {
        code: "2B",
        codeRegion: "94",
        name: "Haute-corse",
        url: "haute-corse",
        seo: "haute-corse-2b",
        coordinates: [9.206388889, 42.39416667]
    },
    {
        code: "21",
        codeRegion: "27",
        name: "Côte-d'or",
        url: "cote-d-or",
        seo: "cote-d-or-21",
        coordinates: [4.772222222, 47.42472222]
    },
    {
        code: "22",
        codeRegion: "53",
        name: "Côtes-d'armor",
        url: "cotes-d-armor",
        seo: "cotes-d-armor-22",
        coordinates: [-2.864166667, 48.44111111]
    },
    {
        code: "23",
        codeRegion: "75",
        name: "Creuse",
        url: "creuse",
        seo: "creuse-23",
        coordinates: [2.018888889, 46.09027778]
    },
    {
        code: "24",
        codeRegion: "75",
        name: "Dordogne",
        url: "dordogne",
        seo: "dordogne-24",
        coordinates: [0.741388889, 45.10416667]
    },
    {
        code: "25",
        codeRegion: "27",
        name: "Doubs",
        url: "doubs",
        seo: "doubs-25",
        coordinates: [6.361666667, 47.16527778]
    },
    {
        code: "26",
        codeRegion: "84",
        name: "Drôme",
        url: "drome",
        seo: "drome-26",
        coordinates: [5.168055556, 44.68416667]
    },
    {
        code: "27",
        codeRegion: "28",
        name: "Eure",
        url: "eure",
        seo: "eure-27",
        coordinates: [0.996111111, 49.11361111]
    },
    {
        code: "28",
        codeRegion: "24",
        name: "Eure-et-Loir",
        url: "eure-et-loir",
        seo: "eure-et-loir-28",
        coordinates: [1.370277778, 48.3875]
    },
    {
        code: "29",
        codeRegion: "53",
        name: "Finistère",
        url: "finistere",
        seo: "finistere-29",
        coordinates: [-4.058888889, 48.26111111]
    },
    {
        code: "30",
        codeRegion: "76",
        name: "Gard",
        url: "gard",
        seo: "gard-30",
        coordinates: [4.180277778, 43.99333333]
    },
    {
        code: "31",
        codeRegion: "76",
        name: "Haute-Garonne",
        url: "haute-garonne",
        seo: "haute-garonne-31",
        coordinates: [1.172777778, 43.35861111]
    },
    {
        code: "32",
        codeRegion: "76",
        name: "Gers",
        url: "gers",
        seo: "gers-32",
        coordinates: [0.453333333, 43.69277778]
    },
    {
        code: "33",
        codeRegion: "75",
        name: "Gironde",
        url: "gironde",
        seo: "gironde-33",
        coordinates: [-0.575277778, 44.82527778]
    },
    {
        code: "34",
        codeRegion: "76",
        name: "Hérault",
        url: "herault",
        seo: "herault-34",
        coordinates: [3.367222222, 43.57972222]
    },
    {
        code: "35",
        codeRegion: "53",
        name: "Ille-et-Vilaine",
        url: "ille-et-vilaine",
        seo: "ille-et-vilaine-35",
        coordinates: [-1.638611111, 48.15444444]
    },
    {
        code: "36",
        codeRegion: "24",
        name: "Indre",
        url: "indre",
        seo: "indre-36",
        coordinates: [1.575833333, 46.77777778]
    },
    {
        code: "37",
        codeRegion: "24",
        name: "Indre-et-Loire",
        url: "indre-et-loire",
        seo: "indre-et-loire-37",
        coordinates: [0.691388889, 47.25805556]
    },
    {
        code: "38",
        codeRegion: "84",
        name: "Isère",
        url: "isere",
        seo: "isere-38",
        coordinates: [5.576111111, 45.26333333]
    },
    {
        code: "39",
        codeRegion: "27",
        name: "Jura",
        url: "jura",
        seo: "jura-39",
        coordinates: [5.697777778, 46.72833333]
    },
    {
        code: "40",
        codeRegion: "75",
        name: "Landes",
        url: "landes",
        seo: "landes-40",
        coordinates: [-0.783888889, 43.96555556]
    },
    {
        code: "41",
        codeRegion: "24",
        name: "Loir-et-Cher",
        url: "loir-et-cher",
        seo: "loir-et-cher-41",
        coordinates: [1.429444444, 47.61666667]
    },
    {
        code: "42",
        codeRegion: "84",
        name: "Loire",
        url: "loire",
        seo: "loire-42",
        coordinates: [4.165833333, 45.72694444]
    },
    {
        code: "43",
        codeRegion: "84",
        name: "Haute-Loire",
        url: "haute-loire",
        seo: "haute-loire-43",
        coordinates: [3.806388889, 45.12805556]
    },
    {
        code: "44",
        codeRegion: "52",
        name: "Loire-Atlantique",
        url: "loire-atlantique",
        seo: "loire-atlantique-44",
        coordinates: [-1.682222222, 47.36138889]
    },
    {
        code: "45",
        codeRegion: "24",
        name: "Loiret",
        url: "loiret",
        seo: "loiret-45",
        coordinates: [2.344166667, 47.91194444]
    },
    {
        code: "46",
        codeRegion: "76",
        name: "Lot",
        url: "lot",
        seo: "lot-46",
        coordinates: [1.604722222, 44.62416667]
    },
    {
        code: "47",
        codeRegion: "75",
        name: "Lot-et-Garonne",
        url: "lot-et-garonne",
        seo: "lot-et-garonne-47",
        coordinates: [0.460277778, 44.3675]
    },
    {
        code: "48",
        codeRegion: "76",
        name: "Lozère",
        url: "lozere",
        seo: "lozere-48",
        coordinates: [3.500277778, 44.51722222]
    },
    {
        code: "49",
        codeRegion: "52",
        name: "Maine-et-Loire",
        url: "maine-et-loire",
        seo: "maine-et-loire-49",
        coordinates: [-0.564166667, 47.39083333]
    },
    {
        code: "50",
        codeRegion: "28",
        name: "Manche",
        url: "manche",
        seo: "manche-50",
        coordinates: [-1.3275, 49.07944444]
    },
    {
        code: "51",
        codeRegion: "44",
        name: "Marne",
        url: "marne",
        seo: "marne-51",
        coordinates: [4.238611111, 48.94916667]
    },
    {
        code: "52",
        codeRegion: "44",
        name: "Haute-Marne",
        url: "haute-marne",
        seo: "haute-marne-52",
        coordinates: [5.226388889, 48.10944444]
    },
    {
        code: "53",
        codeRegion: "52",
        name: "Mayenne",
        url: "mayenne",
        seo: "mayenne-53",
        coordinates: [-0.658055556, 48.14666667]
    },
    {
        code: "54",
        codeRegion: "44",
        name: "Meurthe-et-Moselle",
        url: "meurthe-et-moselle",
        seo: "meurthe-et-moselle-54",
        coordinates: [6.165, 48.78694444]
    },
    {
        code: "55",
        codeRegion: "44",
        name: "Meuse",
        url: "meuse",
        seo: "meuse-55",
        coordinates: [5.381666667, 48.98944444]
    },
    {
        code: "56",
        codeRegion: "53",
        name: "Morbihan",
        url: "morbihan",
        seo: "morbihan-56",
        coordinates: [-2.81, 47.84638889]
    },
    {
        code: "57",
        codeRegion: "44",
        name: "Moselle",
        url: "moselle",
        seo: "moselle-57",
        coordinates: [6.663333333, 49.03722222]
    },
    {
        code: "58",
        codeRegion: "27",
        name: "Nièvre",
        url: "nievre",
        seo: "nievre-58",
        coordinates: [3.504722222, 47.11527778]
    },
    {
        code: "59",
        codeRegion: "32",
        name: "Nord",
        url: "nord",
        seo: "nord-59",
        coordinates: [3.220555556, 50.44722222]
    },
    {
        code: "60",
        codeRegion: "32",
        name: "Oise",
        url: "oise",
        seo: "oise-60",
        coordinates: [2.425277778, 49.41027778]
    },
    {
        code: "61",
        codeRegion: "28",
        name: "Orne",
        url: "orne",
        seo: "orne-61",
        coordinates: [0.128888889, 48.62361111]
    },
    {
        code: "62",
        codeRegion: "32",
        name: "Pas-de-Calais",
        url: "pas-de-calais",
        seo: "pas-de-calais-62",
        coordinates: [2.288611111, 50.49361111]
    },
    {
        code: "63",
        codeRegion: "84",
        name: "Puy-de-Dôme",
        url: "puy-de-dome",
        seo: "puy-de-dome-63",
        coordinates: [3.140833333, 45.72583333]
    },
    {
        code: "64",
        codeRegion: "75",
        name: "Pyrénées-Atlantiques",
        url: "pyrenees-atlantiques",
        seo: "pyrenees-atlantiques-64",
        coordinates: [-0.761388889, 43.25666667]
    },
    {
        code: "65",
        codeRegion: "76",
        name: "Hautes-Pyrénées",
        url: "hautes-pyrenees",
        seo: "hautes-pyrenees-65",
        coordinates: [0.163888889, 43.05305556]
    },
    {
        code: "66",
        codeRegion: "76",
        name: "Pyrénées-Orientales",
        url: "pyrenees-orientales",
        seo: "pyrenees-orientales-66",
        coordinates: [2.522222222, 42.6]
    },
    {
        code: "67",
        codeRegion: "44",
        name: "Bas-Rhin",
        url: "bas-rhin",
        seo: "bas-rhin-67",
        coordinates: [7.551388889, 48.67083333]
    },
    {
        code: "68",
        codeRegion: "44",
        name: "Haut-Rhin",
        url: "haut-rhin",
        seo: "haut-rhin-68",
        coordinates: [7.274166667, 47.85861111]
    },
    {
        code: "69",
        codeRegion: "84",
        name: "Rhône",
        url: "rhone",
        seo: "rhone-69",
        coordinates: [4.641388889, 45.87027778]
    },
    {
        code: "70",
        codeRegion: "27",
        name: "Haute-Saône",
        url: "haute-saone",
        seo: "haute-saone-70",
        coordinates: [6.086111111, 47.64111111]
    },
    {
        code: "71",
        codeRegion: "27",
        name: "Saône-et-Loire",
        url: "saone-et-loire",
        seo: "saone-et-loire-71",
        coordinates: [4.542222222, 46.64472222]
    },
    {
        code: "72",
        codeRegion: "52",
        name: "Sarthe",
        url: "sarthe",
        seo: "sarthe-72",
        coordinates: [0.222222222, 47.99444444]
    },
    {
        code: "73",
        codeRegion: "84",
        name: "Savoie",
        url: "savoie",
        seo: "savoie-73",
        coordinates: [6.443611111, 45.4775]
    },
    {
        code: "74",
        codeRegion: "84",
        name: "Haute-Savoie",
        url: "haute-savoie",
        seo: "haute-savoie-74",
        coordinates: [6.428055556, 46.03444444]
    },
    {
        code: "75",
        codeRegion: "11",
        name: "Paris",
        url: "paris",
        seo: "paris-75",
        coordinates: [2.342222222, 48.85666667]
    },
    {
        code: "76",
        codeRegion: "28",
        name: "Seine-Maritime",
        url: "seine-maritime",
        seo: "seine-maritime-76",
        coordinates: [1.026388889, 49.655]
    },
    {
        code: "77",
        codeRegion: "11",
        name: "Seine-et-Marne",
        url: "seine-et-marne",
        seo: "seine-et-marne-77",
        coordinates: [2.933333333, 48.62666667]
    },
    {
        code: "78",
        codeRegion: "11",
        name: "Yvelines",
        url: "yvelines",
        seo: "yvelines-78",
        coordinates: [1.841666667, 48.815]
    },
    {
        code: "79",
        codeRegion: "75",
        name: "Deux-Sèvres",
        url: "deux-sevres",
        seo: "deux-sevres-79",
        coordinates: [-0.317222222, 46.55555556]
    },
    {
        code: "80",
        codeRegion: "32",
        name: "Somme",
        url: "somme",
        seo: "somme-80",
        coordinates: [2.277777778, 49.95805556]
    },
    {
        code: "81",
        codeRegion: "76",
        name: "Tarn",
        url: "tarn",
        seo: "tarn-81",
        coordinates: [2.166111111, 43.78527778]
    },
    {
        code: "82",
        codeRegion: "76",
        name: "Tarn-et-Garonne",
        url: "tarn-et-garonne",
        seo: "tarn-et-garonne-82",
        coordinates: [1.281944444, 44.08583333]
    },
    {
        code: "83",
        codeRegion: "93",
        name: "Var",
        url: "var",
        seo: "var-83",
        coordinates: [6.218055556, 43.46055556]
    },
    {
        code: "84",
        codeRegion: "93",
        name: "Vaucluse",
        url: "vaucluse",
        seo: "vaucluse-84",
        coordinates: [5.186111111, 43.99388889]
    },
    {
        code: "85",
        codeRegion: "52",
        name: "Vendée",
        url: "vendee",
        seo: "vendee-85",
        coordinates: [-1.297777778, 46.67472222]
    },
    {
        code: "86",
        codeRegion: "75",
        name: "Vienne",
        url: "vienne",
        seo: "vienne-86",
        coordinates: [0.460277778, 46.56388889]
    },
    {
        code: "87",
        codeRegion: "75",
        name: "Haute-Vienne",
        url: "haute-vienne",
        seo: "haute-vienne-87",
        coordinates: [1.235277778, 45.89166667]
    },
    {
        code: "88",
        codeRegion: "44",
        name: "Vosges",
        url: "vosges",
        seo: "vosges-88",
        coordinates: [6.380555556, 48.19666667]
    },
    {
        code: "89",
        codeRegion: "27",
        name: "Yonne",
        url: "yonne",
        seo: "yonne-89",
        coordinates: [3.564444444, 47.83972222]
    },
    {
        code: "90",
        codeRegion: "27",
        name: "Territoire de Belfort",
        url: "territoire-de-belfort",
        seo: "territoire-de-belfort-90",
        coordinates: [6.928611111, 47.63166667]
    },
    {
        code: "91",
        codeRegion: "11",
        name: "Essonne",
        url: "essonne",
        seo: "essonne-91",
        coordinates: [2.243055556, 48.52222222]
    },
    {
        code: "92",
        codeRegion: "11",
        name: "Hauts-de-Seine",
        url: "hauts-de-seine",
        seo: "hauts-de-seine-92",
        coordinates: [2.245833333, 48.84722222]
    },
    {
        code: "93",
        codeRegion: "11",
        name: "Seine-Saint-Denis",
        url: "seine-saint-denis",
        seo: "seine-saint-denis-93",
        coordinates: [2.478055556, 48.9175]
    },
    {
        code: "94",
        codeRegion: "11",
        name: "Val-de-Marne",
        url: "val-de-marne",
        seo: "val-de-marne-94",
        coordinates: [2.468888889, 48.7775]
    },
    {
        code: "95",
        codeRegion: "11",
        name: "Val-d'oise",
        url: "val-d-oise",
        seo: "val-d-oise-95",
        coordinates: [2.131111111, 49.08277778]
    } /* ,
    {
        code: "976",
        codeRegion: "06",
        name: "Mayotte",
        url: "mayotte",
        seo: "mayotte-976",
        coordinates:
    },
    {
        code: "971",
        codeRegion: "01",
        name: "Guadeloupe",
        url: "guadeloupe",
        seo: "guadeloupe-971",
        coordinates:
    },
    {
        code: "973",
        codeRegion: "03",
        name: "Guyane",
        url: "guyane",
        seo: "guyane-973",
        coordinates:
    },
    {
        code: "972",
        codeRegion: "02",
        name: "Martinique",
        url: "martinique",
        seo: "martinique-972",
        coordinates:
    },
    {
        code: "974",
        codeRegion: "04",
        name: "Réunion",
        url: "reunion",
        seo: "reunion-974",
        coordinates:
    } */
]
