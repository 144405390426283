import defaultDiacriticsRemovalMap from '../datas/Diacritics'

const removeDiacritics = str => {
    for (var i = 0; i < defaultDiacriticsRemovalMap.length; i++) {
        str = str.replace(defaultDiacriticsRemovalMap[i].letters, defaultDiacriticsRemovalMap[i].base)
    }
    return str
}

export default removeDiacritics
